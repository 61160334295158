import Layout from "../components/layout/layout";
import React from 'react';
import {
  Typography as MuiTypography,
  Grid, CircularProgress,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { graphql, navigate } from 'gatsby'
import { makeStyles, styled } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import cx from "classnames";
import { LINKS } from "../constants/links";

const Typography = styled(MuiTypography)(spacing);

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flex: 'auto',
    zIndex: 100,
    maxWidth: 1060,
    width: '100%',
    margin: 'auto',
    padding: '0 50px',
    marginBottom: 100,
  },
  articlesContainer: {
    marginTop: 64,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  articleContainer: {
    cursor: "pointer",
    marginBottom: 30,
  },
  previewImage: {
    height: 'auto',
    width: '100%',
    zIndex: 2,
    transition: 'display 1s ease 2s',
    backgroundColor: 'white',
  },
  previewImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    aspectRatio: 2.4,
    overflow: 'hidden',
    marginBottom: 18,
    borderRadius: 10,
    willChange: 'transform',
  },
  spinnerImage: {
    color: "#f15a29",
    position: 'absolute',
  },
  articleTitle: {
    color: "#f15a29",
    textDecoration: 'none',
    marginBottom: 5,
    marginTop: 0,
  },
  articleCreated: {
    fontSize: '.725rem',
    letterSpacing: 1,
    marginBottom: 20,
  },
  shortDescription: {
    fontSize: '.96rem',
    marginBottom: 20,
  },
  articleContent: {
    fontSize: '1rem',
  },
  articleLink: {
    color: "#f15a29",
    textDecoration: 'none',
  },
}));

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    allStrapiCaseStudiesArticles(
      sort: {fields: created_at, order: DESC},
      skip: $skip,
      limit: $limit
    ) {
      edges {
        node {
          Title
          slug
          shortDescription
          image
          id
          created_at
          content
        }
      }
    }
  }
`;

const CaseStudiesArchive = ({ data, pageContext }) => {
  const classes = useStyles();
  const posts = data.allStrapiCaseStudiesArticles.edges;

  const changePage = (event, page) => {
    navigate(`${LINKS.caseStudies}${page !== 1 ? `pages/${page}/` : ''}`);
  };

  const openArticle = event => {
    navigate(`${LINKS.caseStudiesArticle}/${(!!event.currentTarget.dataset.slug 
      ? event.currentTarget.dataset.slug 
      : event.currentTarget.dataset.title)
      .replaceAll(' ', '-').toLowerCase()}/`);
  }

  return <Layout SeoProps={{
    title: 'Case Studies',
    description: 'caseStudies',
  }}>
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <Typography variant="h2">
          Case Studies
        </Typography>
        {
          pageContext.numPages > 1 &&
          <Pagination
            count={pageContext.numPages}
            onChange={changePage}
            defaultPage={pageContext.currentPage}
            shape="rounded"
            hidePrevButton
            hideNextButton
            color="primary"
          />
        }
      </div>
      <Grid container spacing={2} className={classes.articlesContainer}>
        {posts.map(document => {

          //date
          const date = new Date(document.node.created_at).toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            timeZone: 'UTC',
          })

          //image
          let imageFixed = document.node.image;

          if(!imageFixed) {
            const regex = /!\[.*?\]\(.*?\)/gm;
            const found = document.node.content.match(regex);
            if(found && found.length) {
              imageFixed = found[0].split('(')[1].slice(0, -1);
            }
          }

          let findImageWidth;
          if(imageFixed) {
            const searchParams = new URLSearchParams(imageFixed.substring(imageFixed.indexOf('?')));
            const width = searchParams.get('width');
            findImageWidth = imageFixed.split('/width=');
            if (width && findImageWidth.length === 1) {
              findImageWidth = [
                ...findImageWidth,
                width
              ]
            }
          }

          //short description
          let shortDescription = document.node.shortDescription
          // ToDo: clear markdown syntax
          if(!document.node.shortDescription) {
            const removeHeader = document.node.content.split('#');
            const text = removeHeader[0].length > 100 ?
              removeHeader[0] :
              removeHeader[1] && removeHeader[1].length > 100 ?
                removeHeader[1] :
                removeHeader[2] && removeHeader[2] || removeHeader[0]
            shortDescription = text.slice(0, 300).trim() + '...';
          }

          return <Grid item md={4} xs={12} sm={6}
                       key={document.node.id}
                       data-id={document.node.id}
                       data-slug={document.node.slug}
                       data-title={document.node.Title}
                       className={classes.articleContainer}
                       onClick={openArticle}
          >
            {
              imageFixed && <div className={classes.previewImageContainer}>
                <CircularProgress className={classes.spinnerImage}/>
                <img src={findImageWidth[0]} alt="preview image" className={classes.previewImage} />
              </div>
            }
            <h2 className={classes.articleTitle}>
              {document.node.Title}
            </h2>
            <Typography className={cx(classes.typography, classes.articleCreated)} >
              {date}
            </Typography>
            <Typography className={cx(classes.typography, classes.shortDescription)} >
              {shortDescription}
            </Typography>
            <Typography className={classes.articleTitle}>
              Continue reading...
            </Typography>
          </Grid>
        })}
      </Grid>
    </div>
  </Layout>
};

export default CaseStudiesArchive;
